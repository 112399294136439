:root {
  --color-primary: #14854F;
  --color-lightGrey: #d2d6dd;
  --color-grey: #747681;
  --color-darkGrey: #3f4144;
  --color-error: #d43939;
  --color-success: #28bd14;
  --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
  --font-family-sans: -apple-system, BlinkMacSystemFont, Avenir, "Avenir Next",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-mono: monaco, "Consolas", "Lucida Console", monospace;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  line-height: 1.6;
  font-size: var(--font-size);
  font-family: "Segoe UI", "Helvetica Neue", sans-serif; /*fallback*/
  font-family: var(--font-family-sans);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0.35em 0 0.7em 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.85em;
}

a {
  color: var(--color-darkGrey);
  text-decoration: none;
}

a:hover:not(.button) {
  opacity: 0.75;
}

button {
  font-family: inherit;
}

p {
  margin-top: 0;
}

hr {
  border: none;
  background-color: var(--color-lightGrey);
  height: 1px;
  margin: 1rem 0;
}

img {
  max-width: 100%;
}
