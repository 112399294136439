@import "_base.css";
@import "_grid.css";
@import "_form.css";
@import "_nav.css";
@import "_card.css";
@import "_tag.css";
@import "_dropdown.css";
@import "_util.css";

.login-container,
.signup-container {
  margin-top: 10vh;
}

.devices-container,
.collections-container,
.collection-draft-modal-container,
.statistics-container {
  margin-top: 2.5vh;
}

.devices-list, .collections-list {
  max-height: 65vh;
  overflow-y: auto;
}

.collections-list .collection-header {
    display: flex;
}

.collections-list .collection-header .buttons {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    flex: 1;
}

.collections-list .collection-header .buttons .icon {
    padding: 0;
}

.navigation {
  background-color: rgba(247, 248, 249, 0.8);
  border-bottom: 1px solid lightgray;
  padding: 5px;
}

.navigation-dropdown {
  left: auto !important;
  right: 0;
}

.current-position {
    position: absolute;
    padding: 5px 15px 5px 15px;
    top: 40px;
}

.map-controls {
  padding: 5px 5px 0 5px;
  border-top: 1px solid lightgray;
  background-color: rgba(247, 248, 249, 0.8);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.statistics {
   background-color: rgba(247, 248, 249, 0.8);
   max-height: 65vh;
   overflow-y: auto;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -100;
}
