.container {
    max-width: var(--grid-maxWidth);
    margin: 0 auto;
    width: 96%;
    padding: 0 calc(var(--grid-gutter) / 2);
}

.row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
}

.col {
    flex-grow: 1;
}

.col,
[class*=" col-"],
[class^='col-'] {
    margin: 0 calc(var(--grid-gutter) / 2) calc(var(--grid-gutter) / 2);
}

.col-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/1)) - var(--grid-gutter));

    max-width: calc((100% / (12/1)) - var(--grid-gutter));
}

.col-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/2)) - var(--grid-gutter));

    max-width: calc((100% / (12/2)) - var(--grid-gutter));
}

.col-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/3)) - var(--grid-gutter));

    max-width: calc((100% / (12/3)) - var(--grid-gutter));
}

.col-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/4)) - var(--grid-gutter));

    max-width: calc((100% / (12/4)) - var(--grid-gutter));
}

.col-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/5)) - var(--grid-gutter));

    max-width: calc((100% / (12/5)) - var(--grid-gutter));
}

.col-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/6)) - var(--grid-gutter));

    max-width: calc((100% / (12/6)) - var(--grid-gutter));
}

.col-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/7)) - var(--grid-gutter));

    max-width: calc((100% / (12/7)) - var(--grid-gutter));
}

.col-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/8)) - var(--grid-gutter));

    max-width: calc((100% / (12/8)) - var(--grid-gutter));
}

.col-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/9)) - var(--grid-gutter));

    max-width: calc((100% / (12/9)) - var(--grid-gutter));
}

.col-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/10)) - var(--grid-gutter));

    max-width: calc((100% / (12/10)) - var(--grid-gutter));
}

.col-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/11)) - var(--grid-gutter));

    max-width: calc((100% / (12/11)) - var(--grid-gutter));
}

.col-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100% / (12/12)) - var(--grid-gutter));

    max-width: calc((100% / (12/12)) - var(--grid-gutter));
}
