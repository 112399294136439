details.dropdown {
  position: relative;
  display: inline-block;
}

details.dropdown > :last-child {
  position: absolute;
  left: 0;
  white-space: nowrap;
}
